<template>

  <div class="pb-8">

    <div class="d-flex px-4 py-5">

      <v-icon :color="wsATTENTION" class="mr-2">mdi-sitemap-outline</v-icon>

      <div>
        <h4 class="">{{ $t('hr.structure.entity.permissions.title') }}</h4>
        <h5 class="font-weight-regular">{{ $t('hr.structure.entity.permissions.description') }}</h5>
      </div>

    </div>

    <h3 class="pl-10 pb-3">{{ $t('StaffList') }}: </h3>
    <v-sheet
        v-for="item in items.filter(el => !el.is_non_payed)" :key="item.value"
        class="d-flex align-center pl-8 pointer"
        :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
        @click="selectPermission(item)"
    >
      <ws-check-box
          :value="permissions.includes(item.value)"
          :small="false"
          prevent-select
          @prevent="selectPermission(item)"
      />
      <h5>
        {{ item.text }}
      </h5>
    </v-sheet>

    <h3 class="pl-10 pb-3 mt-5">{{ $t('StructureEntities') }}: </h3>
    <v-sheet
        v-for="item in items.filter(el => el.is_non_payed)" :key="item.value"
        class="d-flex align-center pl-8 pointer"
        :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
        @click="selectPermission(item)"
    >
      <ws-check-box
          :value="permissions.includes(item.value)"
          :small="false"
          prevent-select
          @prevent="selectPermission(item)"
      />
      <h5>
        {{ item.text }}
      </h5>
    </v-sheet>

  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsuHrStructurePermissionsSelector",
  props : {
    value : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      permissions : [],
      items : [],
    }
  },
  watch : {
    value() {
      if ( !this.EQ(this.value , this.permissions)) {
        this.permissions = this.COPY(this.value)
      }
    }
  },
  methods : {
    ...mapActions('hr', [
      'GET_HR_STRUCTURE_SELECTOR',
    ]),

    selectPermission(item) {
      if ( !this.permissions.includes(item.value) ) {
        this.permissions.push(item.value)
      } else {
        this.permissions = this.permissions.filter(el=> el !== item.value )
      }
      this.$emit('input' , this.permissions)
    },

    async initPage() {
      let result = await this.GET_HR_STRUCTURE_SELECTOR()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  mounted() {

    if ( this.value ) {
      this.permissions = this.COPY(this.value)
    }
    this.initPage()
  }
}
</script>

<style scoped>

</style>