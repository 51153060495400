<template>
  <div>
    <div v-for="entityType in singleEntitiesArray" :key="entityType" >
      <wsu-structure-select-row
          @select="selectItem"
          :manager-permissions="managerPermissions"
          v-for="(item , i) in singleEntitiesObject[entityType]" :key="i + entityType"
          :item="item"
          :entity-type="entityType"
          :selector-object="selectorObject"
      />
    </div>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import wsuStructureSelectRow
  from "@/components/pages/businessDashboard/businessSettings/businessManagers/UI/wsuStructureSelectRow";

export default {
  name: "wsuManagerStructureSelector",
  components : {
    wsuStructureSelectRow
  },
  props : {
    value : {
      type : Object,
      default() { return [] }
    }
  },
  data() {
    return {
      managerPermissions : {
        institutes : [],
        faculties : [],
        departments : [],
        university_specialties : [],
      },
      selectorObject : {
        institutes : [],
        faculties : [],
        departments : [],
        university_specialties : [],
      },
      singleEntitiesArray : [
          'institute',
          'faculty',
          'department',
          'university_specialty'
      ]
    }
  },
  computed : {
    singleEntitiesObject() {
      return {
        institute : this.selectorObject.institutes,
        faculty : this.selectorObject.faculties.filter(el => !el.institute_id ),
        department : this.selectorObject.departments.filter(el => !el.faculty_id && !el.institute_id ),
        university_specialty : this.selectorObject.university_specialties.filter(el => !el.department_id )
      }
    }
  },
  watch : {
    value : {
      handler () {
        if ( !this.EQ(this.value , this.managerPermissions ) ) {
          this.managerPermissions = this.COPY(this.value)
        }
      },
      deep  : true
    }
  },
  methods : {
    ...mapActions('structure' , [
      'GET_STRUCTURE_SELECTOR' ,
    ]),

    selectItem({ item , entityType , $event }) {
      if ( !entityType ) {
        return
      }

      let type = this.PLURIZE_WORD(entityType)

      if ( !this.managerPermissions[type]) {
        this.managerPermissions[type] = []
      }
      if ( $event && !this.managerPermissions[type].includes(item.value) ) {
          this.managerPermissions[type].push(item.value)
      } else if ( !$event && this.managerPermissions[type].includes(item.value) ) {
        this.managerPermissions[type] = this.managerPermissions[type].filter(el => el !== item.value )
        this.unsetParentEntities(item)
      }

      this.setChildEntities(entityType , item.value , $event )

      this.managerPermissions = this.COPY(this.managerPermissions )

      this.$emit('input' , this.COPY(this.managerPermissions) )

    },
    unsetParentEntities(child) {
      if ( child.institute_id && this.managerPermissions.institutes ) {
        this.managerPermissions.institutes = this.managerPermissions.institutes.filter(el => el !== child.institute_id)
      }
      if ( child.faculty_id && this.managerPermissions.faculties ) {
        this.managerPermissions.faculties = this.managerPermissions.faculties.filter(el => el !== child.faculty_id)
      }
      if ( child.department_id && this.managerPermissions.departments ) {
        this.managerPermissions.departments = this.managerPermissions.departments.filter(el => el !== child.department_id)
      }
    },
    setChildEntities(parentType , parentId , value ) {
      let modifyChildReady = false

      this.singleEntitiesArray.forEach(type => {

        if ( modifyChildReady ) {
          let childEntities = this.selectorObject[this.PLURIZE_WORD(type)].filter(el => el[parentType + '_id'] === parentId )
          childEntities.forEach(child => {
            if ( value && this.managerPermissions[this.PLURIZE_WORD(type)] && !this.managerPermissions[this.PLURIZE_WORD(type)].includes(child.value) ) {
              this.managerPermissions[this.PLURIZE_WORD(type)].push(child.value)
            }
            else if ( !value && this.managerPermissions[this.PLURIZE_WORD(type)] && this.managerPermissions[this.PLURIZE_WORD(type)].includes(child.value) ) {
              this.managerPermissions[this.PLURIZE_WORD(type)] = this.managerPermissions[this.PLURIZE_WORD(type)].filter( el => el !== child.value)
            }
          })
        }
        if ( !modifyChildReady ) {
          modifyChildReady = type === parentType
        }

      })
    },

    async initPage() {
      let result = await this.GET_STRUCTURE_SELECTOR()
      if ( !result ) {
        return
      }
      this.selectorObject = {
        institutes  : result.institutes_select || [],
        faculties   : result.faculties_select || [],
        departments : result.departments_select || [],
        university_specialties : result.university_specialties_select || [],
      }
    }
  },
  mounted() {
    if ( !this.EQ(this.value , this.managerPermissions ) ) {
      this.managerPermissions = this.COPY(this.value)
    }
    this.initPage()
  }
}
</script>

<style scoped>

</style>