<template>
  <div  >
    <!-- Item Title and checkbox-->
    <div class="d-flex align-center" >
      <div style="width: 28px">
        <v-icon v-if="childItems.length > 0 || directDepartments.length > 0" @click="expanded = !expanded">
          mdi-menu-{{ expanded ? 'down' : 'right' }}
        </v-icon>
      </div>

      <ws-check-box
          :value="itemValue"
          :semi-selected="semiSelected"
          @input="selectItem( { item , entityType , $event } )"
          :small="false"
          :color="wsACCENT"
      />
      <div style="width: 100%">
        <h5>{{ item.text }}</h5>
      </div>
    </div>

    <!-- Item Children and checkbox-->
    <v-expand-transition>

      <div v-if="expanded "  >
        <div v-if="!!childEntity">
          <wsu-structure-select-row
              @select="selectItem"
              v-for="(child , i) in childItems" :key="i"
              :item="child"
              :entity-type="childEntity"
              :selector-object="selectorObject"
              :manager-permissions="managerPermissions"
              :parent-type="entityType"
              :parent-id="item.value"
          />
        </div>

        <div v-if="entityType === 'institute' && directDepartments.length > 0"  >
          <wsu-structure-select-row
              @select="selectItem"
              v-for="(child , i) in directDepartments" :key="i + 'direct_departments'"
              :item="child"
              :selector-object="selectorObject"
              :manager-permissions="managerPermissions"
              :parent-type="entityType"
              :parent-id="item.value"
              entity-type="department"
          />
        </div>


      </div>

    </v-expand-transition>

    <v-divider v-if="!childEntity" :style="`border-color : ${wsBACKGROUND}`" />

  </div>
</template>

<script>
export default {
  name: "wsuStructureSelectRow",
  props : {
    child : {
      type : Boolean,
      default : false
    },
    entityType : {
      type : String,
      default : 'institute'
    },
    parentType : {
      type : String,
      default : null
    },
    parentId : {
      type : String,
      default : null
    },
    selectorObject : {
      type : Object,
      default() {
        return {
          institutes : [],
          faculties : [],
          departments : [],
          university_specialties : [],
        }
      }
    },
    managerPermissions : {
      type : Object,
      default() {
        return {
          institutes : [],
          faculties : [],
          departments : [],
          university_specialties : [],
        }
      }
    },
    item : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      expanded : false,
      entitiesArray : [
        'institute',
        'faculty',
        'department',
        'university_specialty'
      ]
    }
  },
  computed : {
    semiSelected() {
      let ids = []
      let checkChildReady = false
      this.entitiesArray.forEach(type => {

        if ( checkChildReady ) {
          let childEntities = this.selectorObject[this.PLURIZE_WORD(type)].filter(el => el[this.entityType + '_id'] === this.item.value )
          childEntities.forEach(child => {
            ids.push(child.value)
          })
        }
        if ( !checkChildReady ) {
          checkChildReady = type === this.entityType
        }

      })


      if ( ids.length === 0 ) {
        return false
      }
      let selectedIds = []
      Object.keys(this.managerPermissions).forEach(key => {
        if ( key !== this.entityType ) {
          selectedIds = [...selectedIds , ...this.managerPermissions[key] ]
        }
      })

      return selectedIds.filter(el => ids.includes(el )).length > 0

    },
    itemValue() {
      if ( !this.entityType ) {
        return false
      }
      if ( (this.managerPermissions[this.PLURIZE_WORD(this.entityType)] || []).includes(this.item.value) ) {
        return true
      }

      return false
    },
    childItems() {
      if ( !this.childEntity ) {
        return []
      }
      if ( !this.selectorObject[this.PLURIZE_WORD(this.childEntity)] ) {
        return []
      }
      let items = this.selectorObject[this.PLURIZE_WORD(this.childEntity)] || []
      items = items.filter( el => el[this.entityType + '_id'] === this.item.value )
      return items
    },
    directDepartments() {
      if ( this.entityType !== 'institute' ) {
        return []
      }
      if ( !this.selectorObject.departments ) {
        return []
      }
      return this.selectorObject.departments.filter(el => el.institute_id === this.item.value && !el.faculty_id )
    },
    childEntity() {
      switch (this.entityType) {
        case 'institute'  : return 'faculty'
        case 'faculty'   : return 'department'
        case 'department' : return 'university_specialty'
        default: return false
      }
    }
  },
  methods : {
    selectItem(dataObject) {
      console.log(dataObject)
      this.$emit('select' , dataObject )
    },
  }
}
</script>

<style scoped>

</style>