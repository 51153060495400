<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="pageTitle"
      :header-action="saveChanges"
      :header-action-text="$t('Save')"
      :header-route="businessDashLink('settings/managers')"
      icon="mdi-chevron-left"
  >

    <template #header_action.desktop>
      <v-btn @click="saveChanges" :color="wsATTENTION" dark class="noCaps"> {{ $t('Save') }}</v-btn>
    </template>
    <template #header_action.mobile>
      <v-btn @click="saveChanges" :color="wsATTENTION" icon class="noCaps"> <v-icon>mdi-content-save-outline</v-icon></v-btn>
    </template>

    <template #default>
      <div class="pt-4 pb-16">
        <template v-for="(block,i) in settingsObjectFiltered" >
          <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`"
                   :key="`block${i}`"
                   class=" mb-3 wsRoundedLight">

            <!-- ::  PERMISSION ITEM ::  -->
            <template v-for="(item,j) in block.items" >
              <v-expand-transition :key="`item${i}${j}`" >
                <div v-if="getPermissionVisibilityCondition(item)"  >

                  <div >

                    <!-- ::  PERMISSION ROW ::  -->
                    <div class="pa-4 d-flex justify-space-between">
                      <!-- ::  Icon Name Description ::  -->
                      <div class="d-flex">
                        <v-icon size="20" :color="wsATTENTION" class="mr-3">{{ MANAGER_PERMISSION_ICON(item.value) }}</v-icon>
                        <div>
                          <h4 style="font-size: 16px; font-weight: 600"  >{{ item.name  }}</h4>
                          <h5 class="font-weight-light grey--text text--darken-3">{{  item.description }}</h5>
                        </div>
                      </div>
                      <!-- ::  Selectors and fields ::  -->
                      <div>
                        <div v-if="item.type === 'ftSelect'"  >

                          <ft-select  :items="item.selectItems" v-model="entityData[item.value]" isBoolean class="pointer">
                            <template #default="{text}">
                              <v-chip
                                  :color="wsACCENT"
                                  :style="!entityData[item.value] ? 'opacity : 0.5' : ''"
                                  class="px-1 justify-end font-weight-medium pointer"
                                  dark
                              >
                                <v-icon  class="mr-1" >
                                  {{ getValueText( item.selectItems,entityData[item.value], true ) || 'mdi-check-circle-outline' }}
                                </v-icon>

                                <h5 class="mx-2">{{ text  }}</h5>

                                <v-icon >mdi-menu-down</v-icon>
                              </v-chip>
                            </template>

                          </ft-select>
                        </div>
                        <v-sheet v-else-if="item.type === 'textField'" color="transparent" min-width="195" width="195"  class="mr-n3">
                          <v-text-field v-model="entityData[item.value]"
                                        rounded
                                        class="compact-form"
                                        :placeholder="item.placeholder"
                                        :append-icon="item.appendIcon"
                                        hide-details
                                        outlined
                                        dense >
                            <template v-if="item.appendText"  slot="append"><h5 style="padding-top: 6px">{{  item.appendText  }}</h5> </template>
                          </v-text-field>
                        </v-sheet>
                      </div>
                    </div>

                    <!-- ::  PERMISSION CUSTOM DATA ::  -->

                    <!-- Course Permissions Selector-->
                    <div v-if="item.value === 'westudy__is_limited' && entityData.westudy__is_limited">
                      <v-divider
                          v-if="block.items.length > 1 "
                          :style="`border-color :  ${wsBACKGROUND}`"
                          :key="`item${i}${j}`" />
                      <courseSelector

                          v-model="coursePermissions"
                          :courses="courses"
                          :categories="categories"
                          :key="`item${i}_westudy`"
                          class="py-8"

                      />
                    </div>

                    <!-- Westudy University Structure Permissions Selector-->
                    <v-expand-transition>
                      <div v-if="item.value === 'wsu-structure__is_limited' && entityData['wsu-structure__is_limited']  ">
                        <v-divider
                            v-if="block.items.length > 1 "
                            :style="`border-color :  ${wsBACKGROUND}`"
                            :key="`item${i}${j}`" />

                        <wsu-manager-structure-selector
                            class="py-8"
                            v-model="managerStructurePermissions"
                        />

                      </div>
                    </v-expand-transition>

                  </div>
                  <v-divider
                      v-if="block.items.length > 1 "
                      :style="`border-color :  ${wsBACKGROUND}`"
                      :key="`item${i}${j}`" />
                </div>
              </v-expand-transition>
            </template>

            <!-- Westudy HR Structure Permissions Selector-->
            <v-expand-transition>
              <wsu-hr-structure-permissions-selector
                  v-if="block.isHrStructure"
                  v-model="hrStructurePermissions"
              />
            </v-expand-transition>

          </v-sheet>
        </template>
      </div>
    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayCourseLimits"
          v-model="displayCourseLimits"
          width="800"
          :title="$t('EditManagerCourseLimits')"
      >
        <courseSelector
            v-model="coursePermissions"
            :courses="courses"
            :categories="categories" />

      </ws-dialog>
    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import courseSelector from "@/components/pages/businessDashboard/UI/courseSelector/courseSelector";
import wsuManagerStructureSelector
  from "@/components/pages/businessDashboard/businessSettings/businessManagers/UI/wsuManagerStructureSelector";
import wsuHrStructurePermissionsSelector
  from "@/components/pages/businessDashboard/businessSettings/businessManagers/UI/wsuHrStructurePermissionsSelector";

export default {
  name: "businessManagerView",
  components : {
    courseSelector,
    wsuManagerStructureSelector,
    wsuHrStructurePermissionsSelector
  },
  props : {
    uuid : {
      type : String,
      default : null
    }
  },
  data() {
    return {

      displayCourseLimits : false,

      entity : {},
      entityData : {},
      coursePermissions : [],
      settingsObject : [],
      courses : [],
      categories : [],
      hrStructurePermissions : [],
      managerStructurePermissions : {
        institutes : [],
        faculties : [],
        departments : [],
        university_specialties : [],
      }

    }
  },
  computed : {
    pageTitle() {
      return this.$t('ManagerPermissions') + ': ' + (this.entity.firstname || '') + ' ' + (this.entity.lastname || '')
    },
    settingsObjectFiltered() {
      let items = this.COPY(this.settingsObject)
      if ( (this.entityData.documentflow && !this.entityData.documentflow__full_access  )|| (this.entityData['wsu-hr'] && !this.entityData['wsu-hr__full_access']) ) {
        items.push({
          name : 'Structure permissions',
          description : 'Structure permissions',
          isHrStructure : true,
          items : []
        })
      }
      return items
    },
    courseSelectorViewer() {
      if (!this.entityData.courseSelector) {
        return []
      }
      if (this.entityData.courseSelector.length === 0) {
        return []
      }
      let result = [];
     // let buffer = JSON.parse(JSON.stringify(this.entityData.courseSelector))
      this.entityData.courseSelector.forEach(value => {
        if ( value.type === 'category') {
          result.push({ value : 'category' , text : 'categoria'  })
        }
      })
      return result
    },
    rootCourseCategories() {
      if ( this.categories.length === 0 ) {
        return [{text: this.$t('All'), value: 'all'}]
      }
      let items = this.categories.filter( el => el.parent === null )
      items.unshift({text: this.$t('All'), value: 'all'} )
      return items
    },
  },
  methods : {
    ...mapActions( 'businessUsers', [ 'GET_MANAGER', 'SAVE_MANAGER_PERMISSIONS' ] ),
    changeCourseLimits($event) {
      if ( $event ) {
        this.displayCourseLimits = true
      }
    },
    async saveChanges() {

      let data = {
        uuid : this.entity.uuid,
        permissions : [],
        course_permissions : this.coursePermissions,
        structure_permissions : this.managerStructurePermissions,
        hr_structure_permissions : this.hrStructurePermissions
      }
      Object.keys(this.entityData).forEach((key)=>{
        if ( this.entityData[key] !== false && this.entityData[key] !== null ) {
          data.permissions.push("MODULE_" + key.toUpperCase())
        }
      })

      if (!await this.SAVE_MANAGER_PERMISSIONS(data)) {
        this.notify(this.$t('Error'))
      }
     this.notify(this.$t('ChangesSaved'))


    },

    //technical
    getPermissionVisibilityCondition(item) {
      return (!item.visibilityField    || (this.entityData[item.visibilityField] === item.visibilityFieldCondition))
          && (!item.invisibilityField  || (this.entityData[item.invisibilityField] !== item.invisibilityFieldCondition) )
    },
    getValueText(items,value, icon = false) {
      if ( !items ) { return '' }
      if ( items.length === 0) { return '' }
      let item = items.find( el=> el.value === value );
      if (!item) { return '' }
      return !icon ? item.text : item.icon
    },
    childCourseCategories(parentId) {
      return this.categories.filter(el => el.parent === parentId )
    },
    categoryCourses(uuid) {
      let items = this.courses
      if (uuid && uuid !== 'all') {
        uuid = uuid !== 'unsorted' ? uuid : null
        items = items.filter( el => el.category_id === uuid )
      }
      return items
    },
    MANAGER_PERMISSION_ICON(permission) {

      switch(permission) {
        case 'westudy' : return 'mdi-school-outline'
        case 'westudy__is_limited' : return 'mdi-table-key'
        case 'westudy__evaluation' : return 'mdi-text-box-check-outline'
        case 'westudy__editor' : return 'mdi-text-box-check-outline'
        case 'leads' : return ' mdi-card-account-phone-outline'
        case 'fineMailer' : return 'mdi-email-newsletter'
        case 'news' : return 'mdi-newspaper-variant-outline'
        case 'managers' : return 'mdi-account-check'
        case 'templates' : return 'mdi-application-edit-outline'
        case 'documentflow' : return 'mdi-file-document-multiple-outline'
        case 'documentflow__full_access' : return 'mdi-file-document-multiple-outline'
        case 'storage' : return 'mdi-cloud'
        case 'wsu-hr' : return 'mdi-account-tie'
        default : return 'mdi-cogs'
      }

    },

    async initPage() {
      let result =  await this.GET_MANAGER(this.uuid) || {}
      if (!result) { this.notify(this.$t('Error')) }


      this.entity = result.manager

      this.settingsObject = result.settings
      this.coursePermissions = result.course_permissions || []
      this.entityData = result.manager_permissions
      this.settingsObject.forEach(block => {
        block.items.forEach(item => {
          if ( !Object.keys(this.entityData).includes(item.value)) {
            this.entityData[item.value] = false
          }
        })
      })
      this.entityData = JSON.parse(JSON.stringify(this.entityData))
      this.hrStructurePermissions = this.entity.hr_structure_permissions

      this.managerStructurePermissions = {
        institutes : this.entity.institutes || [],
        faculties : this.entity.faculties || [],
        departments : this.entity.departments || [],
        university_specialties : this.entity.specialties || [],
      }


    }

  },
  mounted() {
    if (this.uuid) {
      this.initPage()
    }
  }
}
</script>

<style scoped>

</style>